import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Contador = () => {

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [mostrar, setMostrar] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            if (newTimeLeft.days === 0 && newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
                setMostrar(false);
            }
        }, 1000);

        return () => clearTimeout(timer);
    });


    function calculateTimeLeft() {
        // const targetDate = new Date('April 5, 2024 18:00:00').getTime();
        const targetDate = new Date('April 25, 2025 18:00:00').getTime();
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }


    return (
        <div>
            {mostrar && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    className='cuenta-atras'
                >
                    <Typography variant="h5" sx={{ fontWeight: 700}}>
                        {`${timeLeft.days}D ${timeLeft.hours}H ${timeLeft.minutes}M ${timeLeft.seconds}S`}
                    </Typography>
                </Grid>
            )}

        </div>
    )
}
