import React, { useState } from 'react';
import logo from '../media/logo.png';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Contador } from './contador/Contador';


export const HeaderHomeVal = () => {

    const [menu, setMenu] = useState(false);

    const Navigate = useNavigate();

    const mostrarMenu = () => {
        if (menu == true) {
            setMenu(false);
        } else {
            setMenu(true);
        }

    }

    return (
        <div style={{ marginTop: '-5px' }}>
            <Card component="li" className='video-fondo' variant="plain">
                <CardCover>
                    <video
                        className={` ${menu ? 'dark-2' : 'dark'}`}
                        autoPlay
                        loop
                        muted
                        disablePictureInPicture
                        playsInline
                        controls={false}
                        poster="https://www.transbetxi.com/img/video2024.png"
                    >
                        <source
                            src="https://transbetxi.com/videos/video2024.mp4"
                            type="video/mp4"
                        />
                    </video>
                </CardCover>
                <CardContent className='flex-vertical' style={{ justifyContent: 'flex-start' }}>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        // spacing={1}
                        xs={12} sm={6} md={3}
                        style={{ paddingTop: '2em' }}
                        onClick={() => Navigate('/')}
                    >
                        <a href="" style={{ textAlign: 'center' }} >
                            <img src={logo} alt="Logo" />
                        </a>
                        <Contador></Contador>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" alignItems="center" xs={1} md={1}>
                        {menu ? <CloseIcon onClick={() => mostrarMenu()} className="boton-menu fade-in-2" style={{ color: '#000000' }} fontSize="large" />
                            : <MenuIcon onClick={() => mostrarMenu()} className="boton-menu fade-in-2" style={{ color: '#000000' }} fontSize="large" />
                        }
                    </Grid>
                    {menu === true && (
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            // spacing={1}
                            xs={12} md={12}
                            style={{ marginTop: '1em' }}
                            className='fade-in-2'
                        >
                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/actividades')}>
                                <a className="element-custom" href="">
                                    Activitats
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/equipos')}>
                                <a className="element-custom" href="">
                                    Equips / Persones
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/tiempos')}>
                                <a className="element-custom" href="">
                                    Classificació
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/mapas')}>
                                <a className="element-custom" href="">
                                    Mapa dels trams
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/inscripciones')}>
                                <a className="element-custom" href="">
                                    Inscrits
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/reglamentos')}>
                                <a className="element-custom">
                                    Reglaments
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/historia')}>
                                <a className="element-custom" >
                                    Història
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/donde-comer')}>
                                <a className="element-custom">
                                    On menjar
                                </a>
                            </Grid>

                            <Grid container xs={6} md={2} direction="column" justifyContent="center" alignItems="center" className="sirk" onClick={() => Navigate('/patrocinadores')}>
                                <a className="element-custom" href="">
                                    Patrocinadors
                                </a>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </div >

    )
}
